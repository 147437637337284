import fourByFourEarth from './4x4earth.com';
import africa from './africa';
import au from './au';
import beta from './beta';
import eu from './eu';
import nz from './nz';
import us from './us';

const sites = {
    au,
    eu,
    nz,
    us,
    africa,
    beta,
    fourByFourEarth,
};

export default sites;
