const secrets = {
  bingKey: 'Ai1MvZvKryL4zWuxNjOV3u7mlHiv4g83J2XHn7OXTCntwKOVraMT6yv0ytydrnRX',
  thunderforestKey: 'cf406f30bfc54296a9d573ced1e9d6cf',
  sentryDSN: 'https://2df943dd1e7f40119efdcb022366dfd6@o407368.ingest.sentry.io/5276198',
  mapillary: 'MmpGaFphVlZ2dDRnLVRrRTl2SFZhUTpmYjNmN2E1MTFmMTY2OTli',
  flickr: '2108b27ce2576351cad9a7a073447c4d',
  google: 'AIzaSyCyKy9HobWUTlwzCreUnZFbqpTMsora8Hg'
};

export default secrets;
