import secrets from './secrets';
import sites from './sites';

function getSite() {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    if (host.includes('4x4earth.com')) {
        return 'fourByFourEarth';
    }
    switch (subdomain) {
        case 'africa':
            return 'africa';
        default:
        case 'au':
            return 'au';
        case 'beta':
            return 'beta';
        case 'eu':
            return 'eu';
        case 'nz':
            return 'nz';
        case 'us':
            return 'us';
    }
}

const currentSite = getSite();

const config = {
    caption: `
    <a href="http://s3.amazonaws.com/help.track.guide/html/Introduction.html">Help</a> |
    <a href="https://about.track.guide">About</a> |
    <a href="mailto:info@track.guide" target="_self">info@track.guide</a>`,
    defaultLocation: [-27.43882, 153.15731],
    defaultZoom: 10,
    googleApiUrl: `https://maps.googleapis.com/maps/api/js?v=3&key=${secrets.google}`,
    westraDataBaseUrl: 'https://nakarte.me/westraPasses/',
    CORSProxyUrl: 'https://proxy.nakarte.me/',
    elevationsServer: 'https://elevations.track.guide/',
    wikimediaCommonsCoverageUrl: 'https://tiles.nakarte.me/wikimedia_commons_images/{z}/{x}/{y}',
    geocachingSuUrl: 'https://nakarte.me/geocachingSu/geocaching_su2.json',
    tracksStorageServer: 'https://tracks.track.guide',
    getSite,
    ...secrets,
    ...sites[currentSite].siteConfig
};

if (config.title) {
    document.title = config.title;
}

export function getConfigBasedOnSite(
    L,
    BingLayer,
    BingDates,
    GeocachingSu,
    RetinaTileLayer,
    urlViaCorsProxy
) {
    let getLayersDefs = sites[currentSite].getLayersDefs;
    let groupsDefs = sites[currentSite].groupsDefs;
    let titlesByOrder = sites[currentSite].titlesByOrder;
    const layersDefs = getLayersDefs(
        L,
        BingLayer,
        BingDates,
        GeocachingSu,
        RetinaTileLayer,
        urlViaCorsProxy,
        config
    );
    return {
        layersDefs,
        groupsDefs,
        titlesByOrder
    };
}

export default config;
